<template>
	<el-dialog
		title=""
		:visible.sync="createdVisible"
		width="578px"
		center
		:close-on-click-modal="false"
		@close="onResetForm('ruleForm')"
		:show-close="false"
	>
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}合同条款</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="服务名称" prop="name">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="服务单位" prop="unit">
				<el-input v-model.trim="ruleForm.unit" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="单次服务费用" prop="price">
				<el-input v-model.trim="ruleForm.price" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="服务说明" prop="description">
				<el-input v-model.trim="ruleForm.description" :disabled="isShowDetail" type="textarea" autosize placeholder="请输入"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { verifiyPrice } from '@/utils/toolsValidate';
export default {
	data() {
		var checkPrice = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入单次服务费用'));
			} else if (!verifiyPrice(value)) {
				callback(new Error('请输入正确的金额'));
			} else {
				callback();
			}
		};
		return {
			createdVisible: false,
			saveLoading: false,
			ruleForm: {
				name: '',
				unit: '',
				price: '',
				description: '',
			},
			rules: {
				name: [{ required: true, message: '请输入服务名称', trigger: 'blur' }],
				unit: [{ required: true, message: '请输入服务单位', trigger: 'blur' }],
				price: [{ required: true, validator: checkPrice, trigger: 'blur' }],
				description: [{ required: true, message: '请输入服务说明', trigger: 'blur' }],
			},
		};
	},
	props: ['isShowDetail'],
	mounted() {},
	methods: {
		init(info) {
			this.createdVisible = true;
			this.link = info.link;
			if (info.row) {
				this.$nextTick(() => {
					this.ruleForm = JSON.parse(JSON.stringify(info.row));
					this.ruleForm.price = (info.row.price / 100).toFixed(2);
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			//新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},

		onResetForm(formName) {
			this.createdVisible = false;
			this.$refs[formName].resetFields();
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = { ...this.ruleForm, price: this.ruleForm.price * 100 };
					let promiseUrl = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					promiseUrl
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.onResetForm('ruleForm');
								this.saveLoading = false;
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				} else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}
	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
</style>